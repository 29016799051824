import User from '../models/User';
import { useMemo } from 'react';
import { useMe } from '../features/user/api';

export const useUser = (): User => {
  const { data } = useMe();

  return useMemo(() => {
    if (!data) {
      return null as unknown as User;
    }

    return { ...data, roles: Object.values(data.roles) };
  }, [data]);
};
