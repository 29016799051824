import React, { ComponentType, PropsWithChildren } from 'react';
import { IconProps } from './icon/model';
import { CircleExclamationIcon, CircleInfoIcon } from './icon';

interface AlertProps extends PropsWithChildren {
  className?: string;
  color?: 'primary' | 'success' | 'danger' | 'info' | 'warning';
  icon?: ComponentType<IconProps>;
}

export const Alert = ({ color = 'primary', children, className, icon: Icon }: AlertProps) => {
  if (color === 'info') {
    Icon = CircleInfoIcon;
  }

  if (color === 'warning') {
    Icon = CircleExclamationIcon;
  }

  return (
    <div className={`d-flex gap-2 alert alert-${color} ${className}`} role="alert">
      {Icon && <Icon className="fs-5 mt-1" />}
      {children}
    </div>
  );
};
