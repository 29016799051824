import { ComponentType } from 'react';
import { IconProps } from '../components/icon/model';
import { EnFlagIcon, HuFlagIcon, ViFlagIcon, CnFlagIcon } from '../components/icon';

export const STORAGE_LANGUAGE_KEY = 'language';

export enum LocaleKey {
  HU = 'hu',
  EN = 'en',
  VI = 'vi',
  CN = 'cn',
}

export const localeIconMapping: Record<LocaleKey, ComponentType<IconProps>> = {
  [LocaleKey.HU]: HuFlagIcon,
  [LocaleKey.EN]: EnFlagIcon,
  [LocaleKey.VI]: ViFlagIcon,
  [LocaleKey.CN]: CnFlagIcon,
};
