import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { Modal } from '../components/modal';
// It causes circular dependency
import { Button } from '../components/button/Button';
import { useTranslate } from '../translations';

type ConfirmMessageType = string | ReactElement;

interface ConfirmContextProps {
  confirm: (text: ConfirmMessageType) => Promise<boolean>;
}

const ConfirmContext = createContext<ConfirmContextProps>({
  confirm: async () => false,
});

export const ConfirmProvider = ({ children }: PropsWithChildren) => {
  const translator = useTranslate();
  const [message, setMessage] = useState<ConfirmMessageType>();
  const fn = useRef<(choice: boolean) => void>();

  const confirm = useCallback((text: ConfirmMessageType) => {
    return new Promise<boolean>((resolve) => {
      setMessage(text);

      fn.current = (choice: boolean) => {
        resolve(choice);
        setMessage(undefined);
      };
    });
  }, []);

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      <Modal show={!!message}>
        <Modal.Header title={translator('confirm')} />
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button className="px-4" onClick={() => fn.current?.(true)}>
            {translator('yes')}
          </Button>
          <Button className="px-4" onClick={() => fn.current?.(false)} color="dark">
            {translator('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  return useContext(ConfirmContext);
};
