import React, {
  createContext,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import styles from './Modal.module.scss';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

interface ModalContextState {
  onHide: () => void;
}

const ModalContext = createContext<ModalContextState>({ onHide: () => null });

const useModalContext = () => useContext(ModalContext);

const ModalProvider = ({ onHide = () => null, children }: PropsWithChildren<{ onHide?: () => void }>) => (
  <ModalContext.Provider value={{ onHide }}>{children}</ModalContext.Provider>
);

interface ModalProps extends PropsWithChildren {
  show?: boolean;
  onHide?: () => void;
  size?: 'sm' | 'lg' | 'xl';
  clickOutside?: boolean;
}

const bodyClasses = ['modal-open', 'overflow-hidden'];

export const Modal = ({ show, onHide, children, size, clickOutside = true }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const containerElement = document.getElementById('root');

  const handleClickOutside = useCallback<MouseEventHandler>(
    (event) => {
      if (!clickOutside) {
        return;
      }

      if (event.target === modalRef.current) {
        onHide?.();
      }
    },
    [clickOutside, onHide],
  );

  useEffect(() => {
    if (show) {
      document.body.classList.add(...bodyClasses);
    } else {
      document.body.classList.remove(...bodyClasses);
    }

    return () => {
      document.body.classList.remove(...bodyClasses);
    };
  }, [show]);

  if (!show || !containerElement) {
    return null;
  }

  return createPortal(
    <ModalProvider onHide={onHide}>
      <div
        tabIndex={-1}
        className={`modal fade show d-block bg-dark bg-opacity-50 animate__animated animate__fadeIn animate__faster ${styles.modal}`}
        onClick={handleClickOutside}
        aria-hidden="true"
        ref={modalRef}
      >
        <div className={`modal-dialog ${size ? `modal-${size}` : ''}`}>
          <div className="modal-content">{children}</div>
        </div>
      </div>
    </ModalProvider>,
    containerElement,
  );
};

interface HeaderProps extends PropsWithChildren {
  title?: string;
  closeButton?: boolean;
}

const Header = ({ title, closeButton, children }: HeaderProps) => {
  const { onHide } = useModalContext();

  return (
    <div className="modal-header">
      {!!title && <h5 className="modal-title">{title}</h5>}
      {children}
      {closeButton && <button type="button" className="btn-close" aria-label="Close" onClick={() => onHide()} />}
    </div>
  );
};

const Body = ({ className, children }: PropsWithChildren<{ className?: string }>) => {
  return <div className={classNames('modal-body', className)}>{children}</div>;
};

const Footer = ({ children }: PropsWithChildren) => {
  return <div className="modal-footer">{children}</div>;
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
