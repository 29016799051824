import { LocaleKey, useTranslate } from '../translations';
import { useMemo } from 'react';
import { LOCALES } from '../config';

interface LanguageOption {
  value: LocaleKey;
  text: string;
}

export const useLanguageOptions: () => LanguageOption[] = () => {
  const translator = useTranslate();

  return useMemo(
    () =>
      Object.values(LocaleKey)
        .filter((key) => LOCALES.includes(key))
        .map((key) => ({
          value: key,
          text: translator(`language_${key}`),
        })),
    [translator],
  );
};
