import { useCallback, useEffect, useMemo, useState } from 'react';
import { Messages, useLocale } from '../translations';
// It causes a circular dependency
import { useHasAccess } from '../features/access-control/hooks/useHasAccess';
import { Role } from '../enums/Role';

const useFetchMessages = () => {
  const locale = useLocale();

  return useCallback(
    async (path: string, callback: (messages: Record<string, string>) => void) => {
      const response = await fetch(`/translations${path}/${locale}.json`, { cache: 'no-store' });

      callback(await response.json());
    },
    [locale],
  );
};

export const useLoadTranslations = () => {
  const fetchMessages = useFetchMessages();
  const hasAccess = useHasAccess();
  const [messages, setMessages] = useState<Messages>({});
  const [roleBasedMessages, setRoleBasedMessages] = useState<Messages>({});

  useEffect(() => {
    fetchMessages('', setMessages);
  }, [fetchMessages]);

  useEffect(() => {
    if (hasAccess(Role.ADVERT)) {
      fetchMessages('/advert', setRoleBasedMessages);
    } else {
      setRoleBasedMessages({});
    }
  }, [fetchMessages, hasAccess]);

  return useMemo(() => {
    if (!Object.keys(messages).length) {
      return {};
    }

    return {
      ...messages,
      ...roleBasedMessages,
    };
  }, [messages, roleBasedMessages]);
};
